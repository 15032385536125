@import '../../styles/variables';

$height: $navbar-h;

.error-hero {
  background-image: url('../../../public/assets/landing/home.jpg');
  background-size: cover;
  background-position: center $height;
  background-repeat: no-repeat;
  height: 100vh;
}
.error-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-box {
  // background-color: $white;
  padding: 2rem;
  border: 1px solid $black;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  backdrop-filter: blur(10px);
}
