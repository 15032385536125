@import '../../styles/variables';

$height: $navbar-h;

.portfolio-container {
  padding-top: $height;
  text-align: center;
}

.portfolio-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;
  gap: 2rem;
}

.portfolio-box {
  width: 100%;
  max-width: 350px;

  @media screen and (min-width: 900px) {
    max-width: 500px;
  }

  .box-image {
    position: relative;
    img {
      width: 100%;
      border-radius: 5px;
    }
  }

  .box-content {
    width: 100%;
    color: $black;

    h3 {
      text-transform: uppercase;
    }

    p {
      display: none;

      @media screen and (min-width: 769px) {
        display: block;
      }
    }
  }
}

.portfolio-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  scale: 0;
  visibility: hidden;
  transition: scale 500ms ease-in, visibility 500ms ease-in;
}
// .portfolio-box:hover img {
//   filter: blur(0px);
//   scale: 1;
// }
// .portfolio-box:hover .box-content {
//   opacity: 0;
//   visibility: hidden;
// }

.portfolio-box:hover .portfolio-btn {
  scale: 1;
  visibility: visible;
}
