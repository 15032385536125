@import './styles/variables';

/* Works on Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgb(126, 126, 126) #494949;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 2px;
}

*::-webkit-scrollbar-track {
  background: #eaeaea;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(126, 126, 126);
  // border-radius: 20px;
  // border: 3px solid #333;
}

body {
  font-family: $mukta;
  background-color: $white;
}

.row {
  width: 100%;
}

.container {
  margin-inline: 2rem;

  @media screen and (min-width: 1200px) {
    max-width: 1200px;
    margin-inline: auto;
  }
}

a {
  width: fit-content;
}

.section {
  padding-block: 3rem;
}

h2 {
  font-size: clamp(2rem, 5vw, 3rem);
}

.hero-box p {
  font-size: clamp(1rem, 3vw, 1.5rem);
  letter-spacing: 1px;
}

.section-container {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  p {
    font-size: 1rem;
  }

  a {
    color: $black;
  }

  .bottom-border {
    background-color: $black;
    height: 1px;
    width: 100%;
  }
}

.section-container:has(.accordian) {
  gap: 0;

  h2 {
    margin-bottom: 2rem;
  }
}
