@import '../../styles/variables';

footer {
  background-color: $black;
}
.footer-container {
  padding-block: 5rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media screen and (min-width: 900px) {
    flex-direction: row;
  }

  .footer-col {
    width: 100%;
    color: $white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    margin-inline: auto;

    &:not(:last-child) {
      text-align: center;
    }

    &:last-child {
      a {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  a {
    text-decoration: none;
    color: $white;
    display: block;

    &:is(:hover, :focus) {
      text-decoration: underline;
    }
  }
}
