@import '../../styles/variables';

$height: $navbar-h;

.portfolio-id-hero {
  background-size: cover;
  background-position: center $height;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  isolation: isolate;

  .portfolio-id-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .hero-title {
      color: $white;
      font-size: 3rem;
      text-align: center;
    }

    svg {
      color: $white;
      font-size: 5rem;
    }
  }

  &::before {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    inset: 0;
    z-index: -1;
  }
}

.portfolio-gallery-grid {
  display: flex;
  gap: 2rem;
  overflow: auto;
  scroll-snap-type: x mandatory;
  padding-bottom: 1rem;

  @media screen and (min-width: 900px) {
    scroll-snap-type: unset;
    overflow: unset;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .portfolio-gallery-card {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    scroll-snap-align: start;
    // align-self: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    @media screen and (min-width: 900px) {
      flex-shrink: 1;
      scroll-snap-align: unset;
      align-self: unset;
    }

    &::after {
      @media screen and (min-width: 900px) {
        content: '';
        position: absolute;
        inset: 0;
        background-color: rgba(128, 128, 128, 0.6);
        opacity: 0;
        transition: opacity 300ms ease-in;
      }
    }

    span.counter {
      @media screen and (min-width: 900px) {
        display: none;
      }
    }
  }
  .gallery-image-2,
  .gallery-image-3 {
    grid-column: 2 / 3;
  }

  .gallery-image-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  .gallery-image-4 {
    grid-column: 3 / 4;
    grid-row: 2 / 4;
  }

  .gallery-image-5 {
    grid-column: 2 / 3;
    grid-row: span 2;
  }

  .gallery-image-6 {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }
  .gallery-image-7 {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .portfolio-gallery-card-image-box {
    position: relative;
    border: 1px solid $black;

    @media screen and (min-width: 900px) {
      height: 100%;
    }

    span.view {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      background-color: rgba(128, 128, 128, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      @media screen and (min-width: 900px) {
        opacity: 0;
        transition: opacity 300ms ease-in;
      }

      & > svg {
        color: $white;
        font-size: 2rem;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .portfolio-gallery-card:hover::after,
  .portfolio-gallery-card:hover span.view {
    opacity: 1;
  }
}
