@import '../../styles/variables';
.lightbox {
  position: fixed;
  z-index: 30;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: auto;

  svg.close {
    position: absolute;
    top: 3%;
    right: 3%;
    color: $white;
    font-size: 2rem;
    cursor: pointer;

    @media screen and (min-width: 900px) {
      font-size: 3rem;
    }
  }

  .lightbox-gallery {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-block: 5rem;
    height: 100%;

    .lightbox-main {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 70%;

      @media screen and (min-width: 900px) {
        width: 80%;
      }

      .arrow {
        color: $white;
        cursor: pointer;
        position: absolute;
        top: 50%;
        translate: 0 -50%;
        background-color: rgba(0, 0, 0, 0.3);
        font-size: 3rem;
        z-index: 1;
      }

      .arrow.arrow-left {
        border-radius: 0 5px 5px 0;
        left: 0;
      }

      .arrow.arrow-right {
        right: 0;
        border-radius: 5px 0 0 5px;
      }

      .lightbox-main-box {
        height: 100%;
        // max-height: 70%;
        margin-block: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
          // max-width: 90%;
          width: 100%;
          max-height: 100%;
          margin-inline: auto;
        }
      }
    }

    .lightbox-thumbnails {
      display: flex;
      gap: 1rem;
      overflow-y: hidden;
      height: 15%;

      .lightbox-thumbnails-box {
        height: 100%;
        max-width: 100px;
        flex-shrink: 0;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .lightbox-thumbnails-box.focus {
        border: 2px solid goldenrod;
      }
    }
  }
}
