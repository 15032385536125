// Colors
$white: #ffffff;
$black: #000000;
$gray-dark: #707070;
$gray-bright: #9e9e9e;
$hover-color: #0000ee;

// Fonts
$lexend: 'Lexend Deca', sans-serif;
$mukta: 'Mukta', sans-serif;

$bold: 700;
$normal: 400;

//Navbar
$navbar-h: 100px;
