@import '../../styles/variables';

.btn {
  padding-block: 0.5rem;
  padding-inline: 2rem;
  background-color: $black;
  color: $white;
  width: fit-content;
  border: none;
  font-size: 1rem;
  font-family: $mukta;
  cursor: pointer;
  transition: all 300ms ease-in;

  &:hover {
    background-color: $white;
    color: $black;
  }
}
